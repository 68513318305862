<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Login</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div>
          <p>
            To ensure we can assocate the results with you, please sign in or
            setup an account with the options below.
          </p>
          <p>
            <strong>NOTE:</strong> Please use the same email address you plan on
            using when you apply.
          </p>
        </div>
        <div ref="login"></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "../utils/firebase";
var firebaseui = require("firebaseui");
import device from '../utils/device'
import "firebaseui/dist/firebaseui.css";
var ui = new firebaseui.auth.AuthUI(firebase.auth());

export default {
  data() {
    return {
      user: null,
      returningVisitor: false,
    };
  },
  computed: {
    account() {
      if (!this.user) {
        return null;
      }
      return {
        displayName: this.user.displayName,
        name: this.user.displayName,
        uid: this.user.uid,
        photoURL: this.user.photoURL,
        email: this.user.email,
        emailVerified: this.user.emailVerified,
        creationTime: new Date(this.user.metadata.creationTime),
        lastSignInTime: new Date(this.user.metadata.lastSignInTime),
        providerId: this.user.providerData[0].providerId,
      };
    },
  },
  mounted(){
    this.$nextTick(()=>{
      this.init()
    })
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
    },
    init() {
      ui.start(this.$refs.login, {
        // signInSuccessUrl: "/test",
        signInFlow: "popup",
        callbacks: {
          signInSuccess: () => {
            // console.log('SUCCESS')
            let path = device.platform.type === 'desktop' ? '/test/intro' : '/mobile'
            this.$router.push(path)
            return false;
          },
          signInFailure() {
            // console.log("signInFailure", error);
          },
        },
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          //   "yahoo.com",
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // firebase.auth.YahooAuthProvider.PROVIDER_ID,
        ],
        credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,

        // Other config options...
      });
    },
  },
  created() {
    // let count = 0;
    // firebase.auth().onAuthStateChanged((user) => {
    //   count++;
    //   this.user = user;
    //   if (!user) {
    //     this.init();
    //   } else if (user && !user.providerData.length) {
    //     return this.signOut();
    //   }
    //   if (user && count === 1) {
    //     this.returningVisitor = true;
    //     //check for docs instead
    //   }
    //   this.$store.commit("SET_USER", user);
    //   if (user) {
    //       console.log('NAV',user,count)
    //      this.$router.replace({name:'Test',params:{step:'intro'}});
    //   }
    // });
  },
};
</script>

<style>
</style>